const CirclePlay = (props) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9 1.625C4.65117 1.625 1.125 5.15117 1.125 9.5C1.125 13.8488 4.65117 17.375 9 17.375C13.3488 17.375 16.875 13.8488 16.875 9.5C16.875 5.15117 13.3488 1.625 9 1.625ZM9 16.0391C5.38945 16.0391 2.46094 13.1105 2.46094 9.5C2.46094 5.88945 5.38945 2.96094 9 2.96094C12.6105 2.96094 15.5391 5.88945 15.5391 9.5C15.5391 13.1105 12.6105 16.0391 9 16.0391Z'
      fill='#FF4D4F'
    />
    <path
      d='M12.6456 9.27343L7.44069 5.49412C7.39894 5.46352 7.34952 5.44512 7.29793 5.44095C7.24634 5.43679 7.1946 5.44704 7.14849 5.47055C7.10238 5.49406 7.0637 5.52991 7.03677 5.57411C7.00984 5.61831 6.99571 5.66912 6.99596 5.72088V13.2795C6.99596 13.5098 7.25612 13.6399 7.44069 13.5063L12.6456 9.72695C12.6815 9.70106 12.7108 9.66699 12.731 9.62757C12.7511 9.58814 12.7617 9.54448 12.7617 9.50019C12.7617 9.4559 12.7511 9.41224 12.731 9.37281C12.7108 9.33339 12.6815 9.29932 12.6456 9.27343Z'
      fill='#FF4D4F'
    />
  </svg>
);

export default CirclePlay;
